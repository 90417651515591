<template>
	<div
		class="bottom text-center box-shadow-top position-relative  position-relative"
	>
		<div
			v-if="is_pdt_list && items_banner_category.length > 0"
			class="pa-10 under-line "
			style="max-width: 100%; min-height: 75px; white-space: nowrap; overflow-x: scroll; overflow-y: hidden"
		>
			<ul
				class="justify-start flex-scroll gap-20"
			>
				<li
					v-for="category in items_banner_category"
					:key="'category_' + category.uid"

				>
					<div
						class="bg-category flex-column justify-center items-center"
					>
					<button
						class=" radius-10 pa-5-10"
						@click="toCategory(category)"
					><img :src="category.file_path" class=""/></button>
					</div>
					<div class="size-px-10 text-center" style="letter-spacing: -2px"> {{ category.category_name }}</div>
				</li>
			</ul>
		</div>

		<div class=" justify-space-between bg-base">
			<span
				class="flex-1 ptb-10 flex-column"
				@click="onSide"
			>
				<v-icon
					class="color-white"
				>mdi mdi-menu</v-icon>
			</span>

			<span
				class="flex-1 ptb-10 flex-column"
				:class="{on: is_home}"
				@click="toHome"
			>
				<v-icon
					class="color-white"
				>mdi mdi-home-outline</v-icon>
			</span>
			<span
				v-if="false"
				class="flex-1 ptb-10 flex-column position-relative"
				@click="toCart"
				:class="{on: is_cart}"
			>
				<v-icon
					class="color-white"
				>mdi mdi-cart-outline</v-icon>
				<span
					v-if="cart_cnt"
					class="cart-count "
				>{{ cart_cnt }}</span>
			</span>
			<span
				class="flex-1 ptb-10 flex-column"
				@click="toOrderList"
				:class="{on: is_delivery}"
			>
				<v-icon
					class="color-white"
				>mdi mdi-ticket-percent</v-icon>
			</span>
			<span
				class="flex-1 ptb-10 flex-column"
				@click="toMyPage"
				:class="{on: is_mypage}"
			>
				<v-icon
					class="color-white"
				>mdi mdi-account-outline</v-icon>
			</span>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Bottom'
		,props: ['cart_cnt', 'items_banner_category', 'is_member']
		,data: function(){
			return {
			}
		}
		,computed: {
			is_home: function(){
				if(this.$route.path == '/Index'){
					return true
				}else{
					return false
				}
			}
			,is_cart: function(){
				if(this.$route.path == '/Cart'){
					return true
				}else{
					return false
				}
			}
			,is_delivery: function(){
				if(this.$route.path.indexOf('/Order') > -1){
					return true
				}else{
					return false
				}
			}
			,is_mypage: function(){
				if(this.$route.path.indexOf('/Account') > -1){
					return true
				}else{
					return false
				}
			}
			, is_pdt_list: function(){
				if(this.$route.name == 'ProductList'){
					return true
				}else{
					return false
				}
			}
		}
		,methods: {
			toHome: function(){
				this.$storage.push({ name: 'Index'})
			}
			,toMyPage: function(){

				if(!this.is_member){
					this.$storage.push({ name: 'Login'})
					return false
				}
				this.$storage.push({ name: 'MyPage'})
			}
			,toCart: function(){
				this.$storage.push({ name: 'Cart'})
			}
			,toOrderList: function (){

				if(!this.is_member){
					this.$storage.push({ name: 'Login'})
					return false
				}
				this.$storage.push({ name: 'OrderList', params: { type: 'all'}})
			}
			,onSide: function(){
				this.$emit('toggleSide')
			}
			, toCategory: function(category){
				this.$emit('push', { name: 'ProductList', params: { category: category.banner_link}})
			}
		}

		, created() {
		}
		, watch: {
		}
	}
</script>

<style>

	.on { background-color: #eee; color: #fefefe;}
	.on i { color: var(--bg-base) !important;}
</style>